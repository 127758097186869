///
/// @file template_api/products/product_quickshop_v1/src/product_quickshop_v1.scss
///
/// \brief Styles for product quickshop - v1
///
///

@import '../../../../scss/theme-bootstrap';

.product-quickshop-wrapper {
  position: relative;
  &.grid-view {
    clear: both;
  }
}

.product-quickshop {
  border-top: $color-gray solid 1px;
  border-bottom: $color-gray solid 1px;
  margin: 0 auto;
  width: 100%;
  &__content {
    padding: 0 1em;
    @include breakpoint($landscape-up) {
      padding: 0 0 2em 0;
    }
  }
  &__image {
    display: none;
    text-align: $rdirection;
    position: relative;
    // show the image for portrait and larger
    @include breakpoint($portrait-up) {
      display: block;
      float: $ldirection;
      margin-#{$rdirection}: 8%;
      width: 48%;
    }
    @include breakpoint($landscape-up) {
      width: 68%;
    }
    // constrain the image for our slightly smaller qs display
    img {
      max-width: 50%;
    }
  }
  &__details {
    float: none;
    width: 100%;
    padding-top: 3em;
    @include breakpoint($portrait-up) {
      padding-top: 2em;
      float: $rdirection;
      clear: $rdirection;
      width: 44%;
    }
    @include breakpoint($landscape-up) {
      width: 24%;
    }
  }
  &__close {
    .icon--close {
      width: 20px;
      height: 20px;
    }
    .qs-overlay-cbox & {
      display: none;
    }
  }
  &__details-link {
    display: block;
    line-height: 2;
    font-weight: bold;
    text-transform: uppercase;
  }
  &__rating {
    height: 22px;
    position: relative;
    margin-bottom: 10px;
  }
  &__quote {
    @include font-smoothing;
    display: none; // hide for mobile
    top: $spacing--large;
    position: absolute;
    text-align: $ldirection;
    width: 38%;
    max-width: 200px;
    font-size: 16px;
    line-height: get-line-height(16px, 21px);
    font-weight: 300;
    @include breakpoint($portrait-up) {
      display: block; // show portrait up only
      z-index: 1;
      font-size: 22px;
    }
    @include breakpoint($landscape-down) {
      width: 100%;
      max-width: 100%;
      position: static;
      top: 0;
      #{$ldirection}: 0;
    }
    @include breakpoint($large-up) {
      #{$ldirection}: -85px;
    }
    @include breakpoint($landscape-up) {
      max-width: 300px;
      font-size: 22px;
      line-height: 1.25;
      #{$ldirection}: 35px;
    }
  }
  &__misc-flag {
    color: darken($color-gray, 20%);
    min-height: 22px;
  }
  &__name {
    line-height: 1.25;
    margin: 0;
    text-transform: lowercase;
    a,
    a:hover {
      text-decoration: none;
    }
  }
  &__description {
    margin: 0 0 10px;
    overflow: hidden;
    position: relative;
    @include breakpoint($landscape-down) {
      height: auto !important;
    }
    &-more {
      background-color: $white;
      bottom: 0;
      display: none;
      margin: 0;
      position: absolute;
      #{$rdirection}: 0;
      @include breakpoint($landscape-up) {
        display: block;
      }
      a {
        text-decoration: underline;
        text-transform: uppercase;
      }
    }
  }
  &__add-to-bag {
    // @todo standardize these 10px spaces
    margin-bottom: 10px;
    clear: both;
    .product__button--add-to-bag {
      @include button--spp;
    }
  }
  &__action {
    display: block;
    float: $ldirection;
    margin-#{$rdirection}: 1em;
  }
  .product-badge {
    position: absolute;
    text-align: center;
    #{$rdirection}: -20px;
    margin-top: 5px;
    &__image {
      max-width: 100%;
    }
  }
  .product-points {
    display: block;
  }
  .product-full__description-more {
    padding-#{$rdirection}: 75px;
  }
}

.product-badge--align-left {
  .product-quickshop__badge {
    .product-badge {
      #{$ldirection}: 45%;
      #{$rdirection}: auto;
    }
  }
}
